import React from 'react'
import htmlFile from 'raw-loader!./privacy-policy.html';
import { Container } from 'semantic-ui-react';

import styled from 'styled-components';

import { AboutLayout as HomeLayout } from '../components/Layout';
// import HomeLayout from '../components/HomeLayout';
// import './tos.css';

const PPPage = ({ className }) => {
  return (
    <HomeLayout>
      <Container>
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: htmlFile }}
        />
      </Container>
    </HomeLayout>

  )
}

export default styled(PPPage)`
  > .c3 {
    .c0 {
      margin-left: 17.9pt;
    }
  }
  .c2, .c0 {
    text-indent: 0pt;
  }
  .c0, .c2, .c6, .c10, .c11, .c23 {
    text-indent: 0pt;
    line-height: inherit;
  }
  .lst-kix_list_27-0>li:before {
    font-weight: 700;
    font-family: "Arial";
  }

`;
