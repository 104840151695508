import React from 'react';

import MobileNavigation from './MobileNavigation';
import TabletDesktopNavigation from './TabletDesktopNavigation';
import styled from 'styled-components';


const AboutBackground = styled.div`
  background-color: transparent;
  padding-bottom: 0px;
`;

export const BFAboutHeader = () => (
  <AboutBackground> 
    <TabletDesktopNavigation />
    <MobileNavigation />
    <div />
  </AboutBackground>
);

export default BFAboutHeader;
