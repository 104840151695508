import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

// import '../semantic-ui/semantic-ui.css';

import BFHeader from './BFHeader';
import BFAboutHeader from './BFAboutHeader';
import Footer from './Footer';
import { Helmet } from "react-helmet";
import favicon from '../static/favicon.ico';

const Meta = () => {
  const data = useStaticQuery(graphql`
    query MetaQuery {
      logo: imageSharp(original: {src: {glob: "/static/Logo-Tagline-*.png"}}) {
        resize(width: 120) {
          src
          width
          height
        }
      }
    }
  `);
  const seo = {
    url: "https://best-fit.app/",
    title: "BestFit",
    type: "website",
    description: "BestFit connects college students to the resources they need, right when they need them.",
    image: {
      // url: "http://static1.squarespace.com/static/5a691066ccc5c597eec07a07/t/5b26fb9b70a6ad7fc922ff0d/1529281437450/INAlogos+color_FavIcon+Color.png?format=1500w",
      url: _.get(data, 'logo.resize.src'),
      width: 120,
      height: 294,
    },
  }

  return (
    <Helmet>
       <html lang="en" />
      <meta charSet="utf-8" />
      <link rel="shortcut icon" type="image/png" href={favicon} />
      {seo.image && <meta name="image" content={seo.image.url} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.type && <meta property="og:type" content={seo.type} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image.url} />}
      {seo.image && <meta property="og:image:width" content={seo.image.width} />}
      {seo.image && <meta property="og:image:height" content={seo.image.height} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.twitterUsername && <meta name="twitter:creator" content={seo.twitterUsername} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image.url} />}
    </Helmet>
  );
}

export const HomeLayout = ({ children, ...props }) => (
  <div>
    <Meta/>
    <BFHeader/>
    {children}
    <Footer />
  </div>
);

export const AboutLayout = ({ children, ...props }) => (
  <div>
    <Meta/>
    <BFAboutHeader />
    {children}
    <Footer />
  </div>
);

export default HomeLayout;